<template>
    <v-main>
        <link href="https://fonts.googleapis.com/css2?family=Jura:wght@300;400;500;600;700&display=swap" rel="stylesheet">

    <v-container>
        <div>
            <h1 class="text-center">Conditions générales de vente</h1>

            <p>
            <strong>1. APPLICATION ET OPPOSABILITE DES CONDITIONS GENERALES DE VENTE</strong>
            <br>
            Les présentes conditions générales de vente sont systématiquement portées à la connaissance de chaque Client pour lui permettre de passer commande. En conséquence, le fait de passer commande, implique l'adhésion entière et sans réserve du Client aux conditions générales de vente de SF ELEC, ci-après dénommé « le Prestataire » renonçant dès lors à toutes autres, notamment celles qui figureraient sur ses propres documents commerciaux.

            Toute condition contraire posée par le Client sera donc, à défaut d'acceptation expresse, inopposable au Prestataire quel que soit le moment où elle aura pu être portée à sa connaissance.
            </p>

            <p>
            <strong>2. OFFRES – DEVIS.</strong>
            <br>
            Les offres et devis établis gratuitement sont valables pour une durée de quinze jours. Elles sont valables dans la limite des stocks disponibles. En cas de rupture de stocks, le Prestataire se réserve la faculté de livrer un produit offrant des caractéristiques identiques en dimension et qualité.

            Les prix du Prestataire sont libellés en euros hors TVA et ne visent que la fourniture de prestations de services et/ou vente de marchandises décrites dans l’offre à l’exclusion de tous autres travaux et prestations.

            Les informations énoncées par le Client lors de la prise de commande engagent celui-ci : en cas d'erreur dans le libellé de ses coordonnées ou du chantier, le Prestataire ne saurait être tenue responsable de l'impossibilité dans laquelle elle pouvait être de livrer le produit.
            </p>

            <p>
            <strong>3. PROPRIÉTÉ INTELLECTUELLE</strong>
            <br>
            L’ensemble des logos, marques, photos, modèles, plans et dessins figurant sur les documents commerciaux du Prestataire sont la propriété exclusive de celui-ci.
            </p>

            <p>
            <strong>4. COMMANDES – ACOMPTES – MODIFICATIONS ET ANNULATIONS</strong>
            <br>
            Les commandes ne sont définitives que lorsqu'elles ont été confirmées par le règlement d’un acompte de 30% du prix TVAC par le Client, payé à la signature du bon de commande. Durant l’exécution des travaux, suivront une facture de 40% après tubage et blochet, de 20% après l'installation des équipements et la mise sous tension et enfin et de 10% après la réception finale.

            Le montant des acomptes demandés ne constitue pas une estimation de l’ensemble des montants qui pourront être exposés et/ou encourus, notamment dans l’hypothèse de demandes de prestations complémentaires. En fonction de l’évolution des travaux demandés, des heures de travail effectuées et/ou des frais exposés ou encore en raison de circonstances particulières un ou des acomptes complémentaires pourront être demandée au client, dès lors que celui versé précédemment serait épuisé.

            Aucune commande ne peut être annulée ou modifiée sans notre accord préalable écrit. En cas d’annulation de la commande, cet acompte reste acquis au Prestataire à titre de dommages et intérêts.

            Le Prestataire se réserve le droit de refuser d'honorer une commande d'un Client qui n'aurait pas réglé partiellement ou totalement une commande précédente ou avec lequel un litige de paiement serait en cours.
            </p>

            <p>
            <strong>5. DELAIS</strong>
            <br>
            Les délais de livraison ou d’exécution de travaux ne sont donnés qu’à titre indicatif et tout retard de fournitures ou de prestations, ne peut en aucun cas donner lieu à l’annulation de la commande, dommages et intérêts ou indemnité d’aucune nature.
            </p>

            <p>
            <strong>6. FORCE MAJEURE.</strong>
            <br>
            Tout événement indépendant de notre volonté constituant un obstacle insurmontable à l’exécution normale de nos obligations ou nous contraignant à suspendre temporairement ou définitivement l’exécution de nos obligations sera considéré comme cas de force majeure, notamment: les accidents, l'impossibilité d'être approvisionné, les intempéries et leurs conséquences (sols détrempés, impraticables, etc.), les guerres ,les catastrophes naturelles, les grèves ou manifestations, les décisions des autorités,…
            </p>
            <p>
            <strong>7. PAIEMENT</strong>
            <br>
            Toute facture est payable au grand comptant, dès sa réception, sauf dérogation expresse et écrite indiquée de façon manuscrite au recto du bon de commande ou de la facture. Toute facture est réputée acceptée par le Client si elle n’a pas été contestée par recommandé endéans les 8 jours. L’acceptation de la facture est acquise de plein droit et tient lieu, conformément à l’art.1139CC, de mise en demeure par la seule échéance du terme. A partir de l’échéance, toute fraction impayée de la facture portera de plein droit et sans mise en demeure préalable un intérêt moratoire conventionnel de 12% par an. Dans ce cas, le montant de la facture sera, de plein droit et sans sommation, également majoré à titre d'indemnité forfaitaire irréductible de 12% du montant impayé avec un minimum de 250 euros, sans préjudice des intérêts de retard et des judiciaires éventuels. Le fait de demander des délais de paiement ne fait pas obstacle à l’application de la présente clause.
            </p>

            <p>
            <strong>8. EXIGIBILITE</strong>
            <br>
            En cas de non-paiement d’une facture à l’échéance, toutes les factures impayées, même non échues, deviennent immédiatement exigibles ainsi que la suspension de plein droit et sans mise en demeure du chantier en cours, sans dommages et intérêts, ni recours à charge du Prestataire.
            </p>

            <p>
            <strong>9. RETOUR - MODALITES</strong>
            <br>
            Tout retour de produits doit faire l'objet d¹un accord formel entre le vendeur et l'acquéreur.

            Toute reprise acceptée par le vendeur, dans le cas d'un vice apparent ou de non-conformité des produits livrés, qui sera constaté par le vendeur, permettra à l'acheteur d'obtenir le remplacement gratuit ou la restitution d'un avoir à son profit, à l'exclusion de toute autre indemnité ou dommages et intérêts
            </p>

            <p>
            <strong>10. SUSPENSION DES TRAVAUX</strong>
            <br>
            Dès la mise en œuvre des travaux, au cas où ceux-ci devraient être suspendus pour un motif quelconque et indépendant de la volonté du Prestataire, tous frais en résultant de cette suspension temporaire ou définitive seront facturés par le Prestataire au Client qui l’accepte dès à présent.
            </p>

            <p>
            <strong>11. RESERVE DE PROPRIETE</strong>
            <br>
            Toutes les marchandises vendues impayées restent la propriété exclusive du Prestataire jusqu’au paiement intégral de toute somme due. Aucune réclamation ne sera admise, si le client a disposé d’une quelconque façon de tout ou partie de la marchandise litigieuse.
            </p>

            <p>
            <strong>12. PREAVIS</strong>
            <br>
            Les travaux répétitifs sont présumés être exécutés en vertu d’un contrat d’entreprise non écrit qui ne peut être rompu que moyennant un préavis de trois mois. Le Prestataire poursuit l’exécution des travaux pendant la période de préavis. Si le Client s’oppose à la poursuite des travaux, il devra néanmoins payer le prix convenu pour l’exécution des travaux jusqu’à l’échéance du préavis et ce à titre d’indemnité de rupture.
            </p>

            <p>
            <strong>13. RENONCIATION</strong>
            <br>
            Le fait que le Prestataire ne se prévale pas à un moment donné de l'une quelconque des présentes conditions générales de vente, ne peut être interprété comme valant renonciation à se prévaloir ultérieurement de l'une quelconque desdites conditions.
            </p>

            <p>
            <strong>14. LITIGE - COMPETENCE</strong>
            <br>
            Les Tribunaux de Nivelles appliquant le droit belge sont seuls compétents pour connaître de tout litige relatif au présent contrat.            
            </p>
        </div>
    </v-container>
    </v-main>
</template>

<script>
export default {
    
}
</script>

<style scoped>
p{
    text-align: justify;
}

h1{
  font-family: 'Jura', sans-serif;
  font-weight: bold;
  margin-bottom: 30px;
  color:#3D6F9D;
  margin-top: 20px;
}

@media (max-width: 959px) {
    h1{
        margin-top: 70px;
    }   
}
</style>